import React, { useState, useEffect } from 'react';
import RootStore from '../../../store';
import Modal from '../Modal/Modal';
import './styles.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { observer } from 'mobx-react';
import classNames from '../../../common/classNames';
import * as teamMode from '../../../store/teamMode';
import TeamHistoryItemsGenerator from './TeamHistoryItemsGenerator';
import corpStore from '../../../store/CorpStore';
import { TASK_TYPES } from '../../../store/constants';
import _ from 'lodash';

function renderTeamHistoryItem(uniqueTeamHistoryMass, ind, areaNum = null) {
  const { appStore } = RootStore;
  let uniqueTeamHistory = null;
  let isCollapseExist = false;
  let lastTimeChangePrivileges = false;
  let priceVision = true;
  let catCountVision = false;
  const isPlayingAlone = () => {
    if (
      !lastTimeChangePrivileges &&
      uniqueTeamHistoryMass[0].msg_type === 'privileges_changed' &&
      appStore.timedOutCompanyPlayers.length === 4
    ) {
      lastTimeChangePrivileges = true;
      return true;
    } else {
      return false;
    }
  };
  // ToDo: сделать обработку импорта/экспорта/транспортировки и других тасок без task_update. {https://gamesalabuga.atlassian.net/browse/BCC-356}
  const teamHistoryType = uniqueTeamHistoryMass[0].task_update?.type;

  if (
    teamHistoryType &&
    (teamHistoryType === TASK_TYPES.TRADING_LOT_ACKNOWLEDGE ||
      teamHistoryType === TASK_TYPES._ANY_TASK ||
      teamHistoryType === TASK_TYPES._NONE)
  ) {
    return null;
  }
  if (teamHistoryType && [TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST, TASK_TYPES.CITY_TRADE].includes(teamHistoryType)) {
    uniqueTeamHistory = _.cloneDeep(uniqueTeamHistoryMass).reverse();
    isCollapseExist = true;
  } else if (teamHistoryType === TASK_TYPES.FAKE_LTD_ACTION_TASK) {
    const uniqueTeamHistoryMassClone = _.cloneDeep(uniqueTeamHistoryMass);
    uniqueTeamHistory = _.orderBy(uniqueTeamHistoryMassClone, ['server_time'], ['desc']);
    isCollapseExist = true;
  } else if (teamHistoryType && [TASK_TYPES.FAKE_EXPORT_TASK, TASK_TYPES.FAKE_IMPORT_TASK].includes(teamHistoryType)) {
    uniqueTeamHistory = _.cloneDeep(uniqueTeamHistoryMass).reverse();
    if (appStore.isCorpMode && (!appStore.meIsCEO || areaNum !== 'ceo')) {
      priceVision = false;
    }
  } else if (teamHistoryType === TASK_TYPES.FAKE_TRANSPORTATION_TASK) {
    priceVision = false;
    catCountVision = true;
    uniqueTeamHistory = [uniqueTeamHistoryMass[uniqueTeamHistoryMass.length - 1]];
  } else {
    uniqueTeamHistory = [uniqueTeamHistoryMass[uniqueTeamHistoryMass.length - 1]];
  }
  return (
    <TeamHistoryItemsGenerator
      key={ind}
      season={uniqueTeamHistoryMass[0].season}
      isCollapseExist={isCollapseExist}
      uniqueTeamHistory={uniqueTeamHistory}
      isPlayingAlone={isPlayingAlone()}
      priceVision={priceVision}
      catCountVision={catCountVision}
      areaNum={areaNum}
    />
  );
}

function TeamHistoryModal() {
  const { appStore } = RootStore;
  const [seasonHistory, setSeasonHistory] = useState(appStore.currentSeason);
  const [currentAreaNumIndexHistory, setCurrentAreaNumIndexHistory] = useState(0);
  useEffect(() => {
    setSeasonHistory(appStore.currentSeason);
  }, [RootStore.appStore.teamHistoryModal]);
  if (!appStore.me || !appStore.teamHistoryModal) {
    return null;
  }

  const allAreaNums = ['ceo', ...Object.keys(corpStore.areas)];
  const seasonsCount = [];
  for (let i = 0; i <= appStore.currentSeason; i++) {
    seasonsCount.push(i);
  }
  let time_wait = 0;
  let teamHistoryItemsSort;
  let teamHistoryItems;

  const sortTeamHistoryItems = (items) => {
    if (!items) {
      return [];
    }
    return items
      .slice()
      .sort(
        (a, b) =>
          a[a.length - 1]?.server_time - b[b.length - 1]?.server_time ??
          a[a.length - 1]?.task_update?.server_time - b[b.length - 1]?.task_update?.server_time,
      );
  };

  const processTeamHistoryItems = (formattedTeamHistory, allAreaNums, currentAreaNumIndexHistory) => {
    let sortedItems = sortTeamHistoryItems(formattedTeamHistory);

    return sortedItems.map((uniqueTeamHistoryMass, ind) => {
      if (uniqueTeamHistoryMass[0]?.msg_type === 'game_on_pause') {
        return null;
      }
      return renderTeamHistoryItem(uniqueTeamHistoryMass, ind, allAreaNums[currentAreaNumIndexHistory]);
    });
  };

  if (appStore.meIsCEO) {
    const formattedTeamHistory =
      appStore.currentSeason === seasonHistory
        ? corpStore.formattedTeamHistoryByAreaNums[allAreaNums[currentAreaNumIndexHistory]]
        : corpStore.teamHistoryBySeasonsByAreaNums[allAreaNums[currentAreaNumIndexHistory]][seasonHistory];

    teamHistoryItems = processTeamHistoryItems(formattedTeamHistory, allAreaNums, currentAreaNumIndexHistory);
  } else {
    const formattedTeamHistory =
      appStore.currentSeason === seasonHistory
        ? appStore.formattedTeamHistory
        : appStore.teamHistoryBySeasons[seasonHistory];

    if (appStore.with_ltd && !appStore.isCorpMode) {
      teamHistoryItemsSort = sortTeamHistoryItems(formattedTeamHistory);
    } else {
      teamHistoryItemsSort = sortTeamHistoryItems(formattedTeamHistory).reverse();
    }

    teamHistoryItems = teamHistoryItemsSort.map((uniqueTeamHistoryMass, ind) => {
      if (uniqueTeamHistoryMass[0]?.msg_type === 'game_on_pause') {
        return null;
      }
      return renderTeamHistoryItem(uniqueTeamHistoryMass, ind, allAreaNums[currentAreaNumIndexHistory]);
    });
  }
  if (appStore.currentSeason === seasonHistory) {
    teamHistoryItems = teamHistoryItems.filter((item) => item && item.props.season === seasonHistory);
  }
  function close() {
    appStore.hideTeamHistoryModal();
    if (appStore.isCorpMode) {
      setCurrentAreaNumIndexHistory(0);
    }
  }

  function sendRequestForPreviousSeason(season, selectedAreaNum) {
    if (season === appStore.currentSeason) {
      return null;
    }

    let areaNumForSend = null;
    if (appStore.meIsCEO && selectedAreaNum !== 'ceo') {
      areaNumForSend = selectedAreaNum;
    }

    appStore.loadTeamHistoryBySeason(season, areaNumForSend);
  }
  const filteredTeamHistoryItems = teamHistoryItems.filter((item) => item && item.props.season === seasonHistory);
  const filteredTeamHistoryItemsForCEO = teamHistoryItems.filter((item) =>
    item && item.props.season === seasonHistory && item.props.areaNum === 'ceo'
      ? 'Корпорация'
      : `Город ${item?.props.area}`,
  );

  return (
    <>
      <Modal size="very-big" isOpen={appStore.teamHistoryModal} title="История игры" exitIcon={true} onClose={close}>
        <div className="modal__body-seasons">
          <Swiper
            touchStartPreventDefault={false}
            threshold={0}
            touchAngle={40}
            freeModeMomentumRatio={0.2}
            freeModeMomentumVelocityRatio={0.2}
            slidesPerView={'auto'}
            freeMode={true}
            grabCursor={true}
            centerInsufficientSlides={true}
            initialSlide={seasonHistory}
            onClick={(season) => {
              if (season.clickedIndex !== undefined) {
                sendRequestForPreviousSeason(season.clickedIndex, allAreaNums[currentAreaNumIndexHistory]);

                setTimeout(() => {
                  setSeasonHistory(season.clickedIndex);
                }, 10);
              }
            }}
            className="body-balance-seasons"
            refs="swiperSeasons"
          >
            {seasonsCount.map((season) => (
              <SwiperSlide
                key={season}
                className={classNames({
                  'body-balance-season': true,
                  'body-balance-season--active': seasonHistory === season,
                })}
              >
                <p className="body-balance-season-text">{season + 1} сезон</p>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {appStore.meIsCEO && (
          <Swiper
            touchStartPreventDefault={false}
            threshold={0}
            touchAngle={40}
            freeModeMomentumRatio={0.2}
            freeModeMomentumVelocityRatio={0.2}
            slidesPerView={'auto'}
            freeMode={true}
            grabCursor={true}
            centerInsufficientSlides={true}
            initialSlide={currentAreaNumIndexHistory}
            onClick={(areaNum) => {
              if (areaNum.clickedIndex !== undefined) {
                sendRequestForPreviousSeason(seasonHistory, allAreaNums[areaNum.clickedIndex]);
                setTimeout(() => {
                  setCurrentAreaNumIndexHistory(areaNum.clickedIndex);
                }, 10);
              }
            }}
            className="body-balance-seasons"
            refs="swiperSeasons"
          >
            {allAreaNums.map((area) => (
              <SwiperSlide
                key={area}
                className={classNames({
                  'body-balance-season': true,
                  'body-balance-season--active': allAreaNums[currentAreaNumIndexHistory] === area,
                })}
              >
                <p className="body-balance-season-text">{area === 'ceo' ? 'Корпорация' : `Город ${area}`}</p>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {(appStore.meIsCEO ? filteredTeamHistoryItemsForCEO.length : filteredTeamHistoryItems.length) ? (
          <div className="modal__body-history">{teamHistoryItems}</div>
        ) : (
          <div className="modal__body-history-empty">За ход не было никаких действий</div>
        )}
      </Modal>
    </>
  );
}

export default observer(TeamHistoryModal);
